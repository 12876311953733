@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");

@import url("https://pro.fontawesome.com/releases/v5.10.0/css/all.css");

* {
  margin: 0;
}

html,
body {
  font-family: "Open Sans", sans-serif;
  font-weight: 100;
  min-height: 100vh;
  font-size: smaller;
  word-wrap: break-word;
  text-align: justify;
}

.container {
  padding: 2.625rem 1.3125rem;
  max-width: 42rem;
  margin-left: auto;
  margin-right: auto;
}

h1 {
  font-size: 2.3rem;
  font-weight: 100;
  line-height: 1.1;
  margin: 0 0 1.75rem 0;
  padding: 0;
}

h2 {
  margin: 0 0 1.75rem 0;
  padding: 0;
  font-weight: 200;
  line-height: 1.1;
  font-size: 1.7rem;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 3.5rem;
}

p {
  margin: 0 0 1.75rem 0;
  padding: 0;
  font-size: 1.2em;
}



a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.page-active {
  text-decoration: underline;
}

.header {
  display: flex;
  justify-content: space-between;
}

.social-links {
  color: #007acc;
}

footer {
  text-align: center;
  font-size: 1rem;
}

hr {
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    margin-bottom: calc(1.75rem - 1px);
    background: hsla(0,0%,0%,0.2);
    border: none;
    height: 1px;
}

.text-center {
  text-align: center;
}

header {
  margin-bottom: 2rem;
}

.mb-2 {
  margin-bottom: 2rem;
}

.text-right {
  text-align: right;
}

.desktop {
  display: block;
}

.mobile {
  display: none;
}
@media screen and (min-width: 769px) {
  .mobile { display: none; }
  .desktop { display: block; }
}

@media screen and (max-width: 768px) {
  .mobile { display: block; }
  .desktop { display: none; }
}